import Vue from 'vue'
const vm = new Vue()

export const nonLangPageRedirect = function (to) {
  const newTo = Object.assign({}, to, { path: `/${vm.$xp.polyglot.urlPath}${to.path}`.replace('//', '/') })
  return newTo
}

const saveScrollValue = function (appInstance, to, from) {
  if (!to.query.back) {
    let scrollTopValue = null
    const scrollableElement = document.querySelector('div.js-page-content')
    if (scrollableElement) {
      scrollTopValue = scrollableElement.scrollTop
      vm.$xp.history.setHistoryParam(from.params.id, 'lastScrollTop', scrollTopValue)
    }
  }
}

export const beforeEach = async function (to, from, next) {
  vm.$xp.navigation.isEventConsumerEnabled = true
  setTimeout(() => { vm.$xp.navigation.isEventConsumerEnabled = false }, 60)
  // save scroll before leaving page
  !to.query.back && saveScrollValue(vm, to, from)
  if (to.path === '/' && !vm.$xp.device.isLoan && vm.$xp.settings.mergedSettings.content && vm.$xp.settings.mergedSettings.content.startPageID) {
    return next('/_/page/' + vm.$xp.settings.mergedSettings.content.startPageID)
  } else if (vm.$xp.device.platform === 'browser' && vm.$xp.settings.mergedSettings.content && vm.$xp.settings.mergedSettings.content.welcomeScreenId && to.path !== '/') {
    // redirect to welcome screen
    return next({ path: `/${this.$xp.polyglot.urlPath}page/${vm.$xp.settings.mergedSettings.content.welcomeScreenId}`, query: { backToPath: to.path } })
  }
  // special links
  switch (to.path) {
    case '/page/0':
    case '/_/page/0':
      vm.$xp.history.resetHistoryState()
      return next(from.query.backToPath)
    case '/page/next-page':
    case '/_/page/next-page':
      return next({ path: `/page/${vm.$xp.history.nextPageId}`, replace: true })
    case '/page/back':
    case '/_/page/back':
      next(false)
      return vm.$xp.history.back(true)
    case '/page/home':
    case '/_/page/home':
      next(false)
      return vm.$xp.history.goHome(true)
  }
  // if (to.path.includes('preload')) {
  //   // console.log('preloadNextPageId', vm.$xp.content.pageData.NextPageID)
  //   // window.localStorage.setItem('preloadNextPageId', vm.$xp.content.pageData.NextPageID)
  // }
  if (to.name === 'Page') {
    const currentPageData = await vm.$xp.http.loadPage(to)
    console.log('In Router should skip page', currentPageData.Title, currentPageData.Class)
    if (currentPageData && currentPageData.Title === 'SkipPage') {
      return
    } else {
      return next()
    }
  }
  return next()
}

export const handleExternalUrl = function (url) {
  if (vm.$xp.device.isLoan) {
    console.log('should show dialog, device is loan and external url is clicked, this functionality is not permitted on loan devices')
    // window.navigator.notification.alert(vm.$t('global.externalUrlLoanHinweis'), () => { console.log('No External Url alertOnLoan Callback.') }, vm.$t('global.error'), vm.$t('global.continue'))
  } else if (vm.$xp.device.platform === 'browser') {
    window.open(url, 'tab')
  } else if (vm.$xp.device.platform === 'android' || vm.$xp.device.platform === 'ios') {
    if (window.cordova && window.cordova.InAppBrowser) { window.cordova.InAppBrowser.open(url, '_system') }
  }
}

export const getXpedeoPrefixUrlPageId = function (url) {
  url = url.replace('xpedeo://', '')
  let pageId = null
  switch (url) {
    case 'LastPanoramaID': {
      const lastPanoramaPageId = vm.$xp.history.getLastPanoramaPage(vm.$xp.history.lastHomePage.pageId)
      const defaultPanoramaPageId = vm.$xp.content.pageData.Homepage ? vm.$xp.content.pageData.Homepage.DefaultPanoramaPageID : null
      pageId = lastPanoramaPageId || defaultPanoramaPageId
    }
      break
    case 'next':
      pageId = url
      break
    default:
      if (url.includes('preload')) {
        pageId = url
      }
      break
  }

  return pageId
}

// to-do : refactor to take care of types
export const handleUrl = function (url, router, isTab = false) {
  if (typeof url === 'string' && url.startsWith('xpedeo://')) {
    const pageId = getXpedeoPrefixUrlPageId(url)
    if (pageId) {
      if (typeof pageId === 'string' && pageId.includes('preload')) {
        console.log('switch to preload page')
        const urlParts = pageId.split('/').filter(function (i) { return i })
        console.log('urlparts ', urlParts)
        // preloadModules = urlParts[0]
        // console.log()
        const nextPageId = urlParts[1].replace('NID=', '')
        console.log(urlParts)
        window.localStorage.setItem('preloadNextPageId', nextPageId)
        // router.push('/preload/' + urlParts[0])
        console.log('isOnline', vm.$xp.network.isOnline)
        if (!vm.$xp.network.isOnline) {
          console.log('Offline, cannot preload')
          // window.navigator.notification.alert(vm.$t('global.errorOffline'))
        } else {
          vm.$xp.content.showPreloadModal = true
        }
        vm.$xp.content.preloadPath = urlParts[0]
      } else
      if (typeof pageId === 'string' && pageId === 'next') {
        if (vm.$xp.content.pageData.NextPageID) {
          router.push('/page/' + vm.$xp.content.pageData.NextPageID)
        } else {
          console.warn('Splash screen has no next page')
        }
      } else
      if (isTab) {
        !router.history.current.path.includes(pageId) && router.replace({
          path: `/page/${pageId}`,
          query: { replace: true }
        })
      } else {
        router.push(`/page/${pageId}`)
      }
    } else {
      console.error('Cannot determine pageId from Url: ', url)
    }
  } else if (isTab) {
    !router.history.current.path.includes(url) && router.replace({ path: `/page/${url}`, query: { replace: true } })
  } else if (typeof url === 'string' && url.startsWith('http')) {
    handleExternalUrl(url)
  } else if (typeof url === 'string' && (url.startsWith('/page/') || url.startsWith('#/page/'))) {
    if (url.startsWith('#/page/')) url = url.replace('#', '')
    router.push(url)
  } else if (typeof url === 'string' && url.startsWith('/lang')) {
    const urls = url.split('/') // e.g : /lang/en_EN/en/page/xxxx
    const langIndex = url.indexOf('lang')
    const langCode = urls[langIndex + 1]
    const langPath = urls[langIndex + 2]
    const pageId = urls[langIndex + 4]
    console.log(urls)
    window.localStorage.setItem('nfcLangCode', langCode)
    vm.$xp.polyglot.setLanguage(langCode, langPath)
    router.push(`/page/${pageId}`)
  }
}
