import throttle from '../util/throttle'

const hotspotsMixin = {
  props: {
    hotspots: {
      type: Array
    }
  },
  computed: {
    htmlIconHotspots () {
      return this.hotspots && this.hotspots.filter(item => item.shape === 'rect' && (
        (this.$xp.settings.mergedSettings.navigation.imageMapDefaultIcon && item.icon !== '') ||
        (!this.$xp.settings.mergedSettings.navigation.imageMapDefaultIcon && item.icon > '')
      ))
        .map(item => {
          return {
            centerYPercent: ((item.tl.y + item.br.y) / 2) / 100,
            centerXPercent: ((item.tl.x + item.br.x) / 2) / 100,
            alt: item.alt,
            href: this.$router.resolve(item.href).href,
            route: item.href,
            icon: item.icon || 'default',
            icontext: item.icontext ? item.icontext : ''
          }
        })
    }
  },
  created () {
    this.hotspotClicked = throttle(this.hotspotClicked)
    this.hotspotIconClicked = throttle(this.hotspotIconClicked)
  },
  methods: {
    checkForHotspot (coords) {
      this.hotspots.map(hotspot => {
        switch (hotspot.shape) {
          case 'poly':
            if (this.isInPolyHotspot(coords, hotspot)) {
              this.hotspotClicked(hotspot)
              return true
            }
            break
          case 'circle':
            if (this.isInCircleHotspot(coords, hotspot)) {
              this.hotspotClicked(hotspot)
              return true
            }
            break
          case 'rect':
            if (this.isInRectHotspot(coords, hotspot)) {
              this.hotspotClicked(hotspot)
              return true
            }
            break
        }
      })
    },
    isInCircleHotspot (point, hotspot) {
      return Math.sqrt(Math.pow((point[0] - hotspot.center.x), 2) + Math.pow((point[1] - hotspot.center.y), 2)) <= hotspot.radius
    },
    isInRectHotspot (point, hotspot) {
      return point[0] >= hotspot.tl.x &&
        point[0] <= hotspot.br.x &&
        point[1] > hotspot.tl.y &&
        point[1] <= hotspot.br.y
    },
    isInPolyHotspot (point, hotspot) {
      const vs = hotspot.points.map(point => [point.x, point.y])
      const x = point[0]
      const y = point[1]
      let inside = false
      for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0]
        const yi = vs[i][1]
        const xj = vs[j][0]
        const yj = vs[j][1]
        const intersect = ((yi > y) !== (yj > y)) &&
          (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
        if (intersect) inside = !inside
      }
      return inside
    },
    hotspotClicked (hotspot) {
      this.$emit('hotspot-click', hotspot)
    },
    hotspotIconClicked (hotspot, clickargs, item) {
      item.href = item.href.replace('#', '')
      this.$emit('hotspot-click', item)
    }
  }
}

export default hotspotsMixin
